<template>
  <div>
    <!--Banner Wrap Start-->
    <div class="iner_banner">
      <div class="container">
        <h5>NAAC</h5>
      </div>
    </div>
    <!--Banner Wrap End-->

    <section>
      <div class="container">
        <!--about_courses START-->
        <div class="about_courses">
          <!--about_courses_thumb START-->
          <div class="about_courses_thumb">

            <!--Tab Menu Wrap Start-->
            <div class="iq_tab_menu">
              <ul class="naac-tabs" id="tabs" data-tabs="tabs">
                <li class="active"><a data-toggle="tab" href="#tab1">IIQA</a></li>
                <li><a data-toggle="tab" href="#tab2">SSR</a></li>
                <li><a data-toggle="tab" href="#tab3">IQAC</a></li>
                <li><a data-toggle="tab" href="#tab4">Statutory committees</a></li>
                <li v-for="(value, key) in naacDocuments" :key="key"><a data-toggle="tab" :href="value.title | toLink">{{
                    value.title
                  }}</a>
                </li>
              </ul>
            </div>

            <!--Tab Menu Wrap End-->
            <div id="my-tab-content" class="tab-content">
              <div class="tab-pane active" id="tab1">
                <div class="about_courses_thumb_capstion">
                  <h5 class="title">Institutional Information for Quality Assessment (IIQA)</h5>
                  <div class="row">
                    <div class="col-md-12 col-sm-6">
                      <div class="text">
                        <p>
                          <a class="text-dark" target="_blank" :href="selfDeclaration">Self Declaration</a>
                        </p>
                      </div>
                    </div>
                    <div class="col-md-12 col-sm-6">
                      <div class="text">
                        <p>
                          <a class="text-dark" target="_blank" :href="undertaking">Undertaking</a>
                        </p>
                      </div>
                    </div>
                    <div class="col-md-12 col-sm-6">
                      <div class="text">
                        <p>
                          <a class="text-dark" target="_blank" :href="finalSIFReport">Final SIF Report</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="tab2">
                <div class="about_courses_thumb_capstion">
                  <h5 class="title">Self-Study Report (SSR)</h5>
                </div>
              </div>
              <div class="tab-pane" id="tab3">
                <div class="about_courses_thumb_capstion">
                  <h5 class="title">Internal Quality Assurance Cell (IQAC)</h5>
                  <div v-if="iqacList">
                    <div v-for="item in iqacList" :key="item.title">
                      <h5>{{ item.title }}</h5>
                      <ul v-for="link in item.links" :key="link">
                        <li><a class="text-dark" target="_blank" :href="link.link">{{ link.name }}</a></li>
                      </ul>
                      <br>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="tab4">
                <div class="about_courses_thumb_capstion">
                  <h5 class="title">Statutory committees - MOM</h5>
                  <div v-for="committee in committeeList" :key="committee.title">
                    <h5>{{ committee.title }}</h5>
                    <ul v-for="link in committee.links" :key="link">
                      <li><a class="text-dark" target="_blank" :href="link.link">{{ link.name }}</a></li>
                    </ul>
                    <br>
                  </div>
                </div>
              </div>
              <div class="tab-pane" :id="value.title | toId" v-for="(value, key) in naacDocuments" :key="key">
                <div class="naac-title text-center">
                  <h5 class="text-capitalize">{{ value.name }}</h5>
                </div>
                <div v-if="value.tableData">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th v-for="item in naacTableFields" :key="item.key">{{ item.label }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in value.tableData" :key="index">
                      <td>{{ item.metric }}</td>
                      <td><a class="text-dark" :href="item.link" target="_blank">{{ item.parameter }}</a></td>
                      <td>{{ item.weightage }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div v-if="value.tableGroupData">
                  <div v-for="(groupItem, index) in value.tableGroupData" :key="index">
                    <div class="text-center bg-success mb-2">
                      <b>{{groupItem.name}}</b>
                    </div>
                    <table class="table table-striped">
                      <thead>
                      <tr>
                        <th v-for="item in value.tableFields" :key="item.key">{{ item.label }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, index) in groupItem.tableGroupData" :key="index">
                        <td>{{ item.metric }}</td>
                        <td><a class="text-dark" :href="item.link" target="_blank" v-html="item.description"></a></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div v-if="value.list">
                  <div v-for="item in value.list" :key="item.title">
                    <h5>{{ item.title }}</h5>
                    <ul v-for="link in item.links" :key="link">
                      <li><a class="text-dark" target="_blank" :href="link.link">{{ link.name }}</a></li>
                    </ul>
                    <br>
                  </div>
                </div>
                <div v-if="value.feedbackForms">
                  <div class="row">
                    <div class="col-md-3" v-for="item in value.feedbackForms" :key="item.name">
                      <a :href="item.link" target="_blank">
                        <div class="form-box">
                          <i :class="item.icon" aria-hidden="true"></i>
                          <div>{{ item.name }}</div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--about_courses end-->
      </div>
    </section>

  </div>
</template>

<script>
export default {
  name: 'Naac',
  // ...
  data () {
    return {
      selfDeclaration: '/docs/Self Declairation.pdf',
      undertaking: '/docs/undertaking NAAC.pdf',
      finalSIFReport: '/docs/Final SIF Report (1).pdf',
      iqacList: [
        {
          title: '',
          links: [
            {
              name: 'IQAC',
              link: 'https://ytipharmacy.com/naac/IQAC.pdf'
            }
          ]
        }
      ],
      committeeList: [
        {
          title: 'Anti Ragging Committee',
          links: [
            {
              name: 'Anti Ragging Committee 2017-18 MOM',
              link: '/docs/antiragging Committee 2017-18 MOM .pdf'
            },
            {
              name: 'Anti Ragging Committee 2018-19 MOM',
              link: '/docs/Antiragging Committee 2018-19.pdf'
            },
            {
              name: 'Anti Ragging Committee 2019-20 MOM',
              link: '/docs/Antiragging Committee 2019-20 MOM.pdf'
            },
            {
              name: 'Anti Ragging Committee 2020-21 MOM',
              link: '/docs/Antiragging Committee 2020-21MOM.pdf'
            },
            {
              name: 'Anti Ragging Committee 2021-22 MOM',
              link: '/docs/Antiragging Committee 2021-22 MOM  .pdf'
            }
          ]
        },
        {
          title: 'SC ST Grievance',
          links: [
            {
              name: 'SC ST GRIEVANCE 2017-18',
              link: '/docs/SC ST Grievance committee 2017-18.pdf'
            },
            {
              name: 'SC ST GRIEVANCE 2018-19',
              link: '/docs/SC ST GRIEVANCE 2018- 19.pdf'
            },
            {
              name: 'SC ST GRIEVANCE 2019-20',
              link: '/docs/SC ST Grievance 2019-20.pdf'
            },
            {
              name: 'SC ST GRIEVANCE 2020-21',
              link: '/docs/SC ST Grievance committee 2021-22.pdf'
            }
          ]
        },
        {
          title: 'Internal Complaint Committee',
          links: [
            {
              name: 'ICC 2018-19',
              link: '/docs/ICC 2018-19.pdf'
            },
            {
              name: 'ICC 2019-20',
              link: '/docs/ICC 19-20.pdf'
            },
            {
              name: 'ICC 2020-21',
              link: '/docs/ICC 2020-21.pdf'
            },
            {
              name: 'ICC 2021-22',
              link: '/docs/ICC 2021-22.pdf'
            }
          ]
        }
      ],
      naacTableFields: [
        {
          key: 'metric',
          label: 'Metric'
        },
        {
          key: 'link',
          label: 'Parameter'
        },
        {
          key: 'weightage',
          label: 'Weightage'
        }
      ],
      naacDocuments: [
        {
          title: 'CRITERION 1',
          name: 'Criterion I – Curricular Aspects (100)',
          tableData: [
            {
              metric: '1.1.1 QlM',
              parameter: 'The Institution ensures effective curriculum planning and delivery through a well-planned and documented process including Academic calendar and conduct of continuous internal Assessment',
              weightage: '20',
              link: 'https://ytipharmacy.com/naac/1.1.1facesheet.pdf'
            },
            {
              metric: '1.2.1 QlM',
              parameter: 'Number of Add on /Certificate/Value added programs offered and online MOOC programs like SWAYAM, NPTEL etc. where the students of the institution have benefitted during the last five years)',
              weightage: '15',
              link: 'https://ytipharmacy.com/naac/1.2.1facesheet.pdf'
            },
            {
              metric: '1.2.2 QnM',
              parameter: 'Percentage of students enrolled in Certificate/ Add-on/Value added programs and also completed online MOOC programs like SWAYAM, NPTEL etc.as against the total number of students during the last five years',
              weightage: '15',
              link: 'https://ytipharmacy.com/naac/1.2.2facesheet.pdf'
            },
            {
              metric: '1.3.1. QlM',
              parameter: 'Institution integrates crosscutting issues relevant to Professional Ethics, Gender, Human Values, Environment and Sustainability in transacting the Curriculum',
              weightage: '10',
              link: 'https://ytipharmacy.com/naac/1.3.1facesheet.pdf'
            },
            {
              metric: '1.3.2. QnM',
              parameter: 'Percentage of students undertaking project work/field work/ internships (Data for the latest completed academic year)',
              weightage: '20',
              link: 'https://ytipharmacy.com/naac/1.3.2facesheet.pdf'
            },
            {
              metric: '1.4.1. QnM',
              parameter: 'Institution obtains feedback on the academic performance and ambience of the institution from various takeholders, such as Students, Teachers, Employers, Alumni etc. and action taken report on the feedback is made available on institutional website',
              weightage: '20',
              link: 'https://ytipharmacy.com/naac/1.4.1facesheet.pdf'
            }
          ]
        },
        {
          title: 'CRITERION 2',
          name: 'Criteria 2 - Teaching- Learning and Evaluation (350)',
          tableData: [
            {
              metric: '2.1.1 QnM',
              parameter: 'Enrolment percentage',
              weightage: 20,
              link: 'https://ytipharmacy.com/naac/2.1.1facesheet.pdf'
            }, {
              metric: '2.1.2 QnM',
              parameter: 'Percentage of seats filled against seats reserved for various categories (SC, ST, OBC, etc. as per applicable reservation policy) during the last five years',
              weightage: 20,
              link: 'https://ytipharmacy.com/naac/2.1.2facesheet.pdf'
            }, {
              metric: '2.2.1 QnM',
              parameter: 'Student – Full time Teacher Ratio',
              weightage: 40,
              link: 'https://ytipharmacy.com/naac/2.2.1facesheet.pdf'
            }, {
              metric: '2.3.1 QlM',
              parameter: 'Student centric methods, such as experiential learning, participative learning and problem solving methodologies are used for enhancing learning experiences using ICT tools',
              weightage: 40,
              link: 'https://ytipharmacy.com/naac/2.3.1facesheet.pdf'
            }, {
              metric: '2.4.1 QnM',
              parameter: 'Percentage of full-time teachers against sanctioned posts during the last five years',
              weightage: 15,
              link: 'https://ytipharmacy.com/naac/2.4.1facesheet.pdf'
            }, {
              metric: '2.4.2 QnM',
              parameter: 'Percentage of full time teachers with NET/SET/SLET/ Ph. D./D.Sc. / D.Litt. during the last five years (consider only highest degree for count)',
              weightage: 25,
              link: 'https://ytipharmacy.com/naac/2.4.2facesheet.pdf'
            }, {
              metric: '2.5.1 QlM',
              parameter: 'Mechanism of internal/ external assessment is transparent and the grievance redressal system is time- bound and efficient',
              weightage: 40,
              link: 'https://ytipharmacy.com/naac/2.5.1facesheet.pdf'
            }, {
              metric: '2.6.1 QlM',
              parameter: 'Programme Outcomes (POs) and Course Outcomes (COs) for all Programmes offered by the institution are stated and displayed on website and attainment of POs and COs are evaluated',
              weightage: 25,
              link: 'https://ytipharmacy.com/naac/2.6.1facesheet.pdf'
            }, {
              metric: '2.6.2 QlM',
              parameter: 'Attainment of POs and COs are evaluated.',
              weightage: 20,
              link: 'https://ytipharmacy.com/naac/2.6.2facesheet.pdf'
            }, {
              metric: '2.6.3 QnM',
              parameter: 'Pass percentage of Students during last five year',
              weightage: 45,
              link: 'https://ytipharmacy.com/naac/2.6.3facesheet.pdf'
            }, {
              metric: '2.7.1 QnM',
              parameter: 'Online student satisfaction survey regarding to teaching learning process.',
              weightage: 60,
              link: 'https://ytipharmacy.com/naac/2.7.1facesheet.pdf'
            }
          ]
        },
        {
          title: 'CRITERION 3',
          name: 'Criteria 3 - Research, Innovations and Extension (110)',
          tableData: [
            {
              metric: '3.1.1 QnM',
              parameter: 'Grants received from Government and non-governmental agencies for research projects / endowments in the institution during the last five years (INR in Lakhs)',
              weightage: 10,
              link: 'https://ytipharmacy.com/naac/3.1.1facesheet.pdf'
            }, {
              metric: '3.2.1 QlM',
              parameter: 'Institution has created an ecosystem for innovations and has initiatives for creation and transfer of knowledge',
              weightage: 10,
              link: 'https://ytipharmacy.com/naac/3.2.1facesheet.pdf'
            }, {
              metric: '3.2.2 QnM',
              parameter: 'Number of workshops/seminars/conferences including on Research Methodology, Intellectual Property Rights (IPR) and entrepreneurship conducted during the last five years',
              weightage: 5,
              link: 'https://ytipharmacy.com/naac/3.2.2facesheet.pdf'
            }, {
              metric: '3.3.1 QnM',
              parameter: 'Number of research papers published per teacher in the Journals notified on UGC care list during the last five years',
              weightage: 10,
              link: 'https://ytipharmacy.com/naac/3.3.1facesheet.pdf'
            }, {
              metric: '3.3.2 QnM',
              parameter: 'Number of books and chapters in edited volumes/books published and papers published in national/ international conference proceedings per teacher during last five years',
              weightage: 15,
              link: 'https://ytipharmacy.com/naac/3.3.2facesheet.pdf'
            }, {
              metric: '3.4.1 QlM',
              parameter: 'Extension activities are carried out in the neighborhood community, sensitizing students to social issues, for their holistic development, and impact thereof during the last five years',
              weightage: 15,
              link: 'https://ytipharmacy.com/naac/3.4.1facesheet.pdf'
            }, {
              metric: '3.4.2 QlM',
              parameter: 'Awards and recognitions received for extension activities from government / government recognised bodies',
              weightage: 5,
              link: 'https://ytipharmacy.com/naac/3.4.2facesheet.pdf'
            }, {
              metric: '3.4.3 QnM',
              parameter: 'Number of extension and outreach programs conducted by the institution through NSS/NCC etc., (including the programmes such as Swachh Bharat, AIDS awareness, Gender issues etc. and/or those organised in collaboration with industry, community and NGOs) during the last five years',
              weightage: 20,
              link: 'https://ytipharmacy.com/naac/3.4.3facesheet.pdf'
            }, {
              metric: '3.5.1 QnM',
              parameter: 'The number of MoUs, collaborations/linkages for Faculty exchange, Student exchange, Internship, Field project, On-the-job training, research and other academic activities during the last five years',
              weightage: 20,
              link: 'https://ytipharmacy.com/naac/3.5.1facesheet.pdf'
            }
          ]
        },
        {
          title: 'CRITERION 4',
          name: 'Criterion 4 – Infrastructure and Learning Resources (100)',
          tableData: [
            {
              metric: '4.1.1 QlM',
              parameter: 'Availability of adequate infrastructure and physical facilities',
              weightage: 20,
              link: 'https://ytipharmacy.com/naac/4.1.1facesheet.pdf'
            },
            {
              metric: '4.1.2 QnM',
              parameter: 'Percentage of expenditure, excluding salary for infrastructure augmentation during last five years (INR in Lakhs)',
              weightage: 10,
              link: 'https://ytipharmacy.com/naac/4.1.2facesheet.pdf'
            },
            {
              metric: '4.2.1 QlM',
              parameter: 'Library automation using Integrated Library Management System (ILMS), subscription to e-resources including provision of links to OER repositories, amount spent on purchase of books, journals and usage of library',
              weightage: 20,
              link: 'https://ytipharmacy.com/naac/4.2.1facesheet.pdf'
            },
            {
              metric: '4.3.1 QlM',
              parameter: 'Institution frequently updates its IT facilities and provides sufficient bandwidth for internet connection',
              weightage: 20,
              link: 'https://ytipharmacy.com/naac/4.3.1facesheet.pdf'
            },
            {
              metric: '4.3.2 QnM',
              parameter: 'Student – Computer ratio (Data for the latest completed academic year)',
              weightage: 10,
              link: 'https://ytipharmacy.com/naac/4.3.2facesheet.pdf'
            },
            {
              metric: '4.4.1 QnM',
              parameter: 'Percentage of expenditure incurred on maintenance of infrastructure (physical and academic support facilities) excluding salary component during the last five years (INR in Lakhs)',
              weightage: 20,
              link: 'https://ytipharmacy.com/naac/4.4.1facesheet.pdf'
            }
          ]
        },
        {
          title: 'CRITERION 5',
          name: 'Criterion 5 - Student Support and Progression (140)',
          tableData: [
            {
              metric: '5.1.1 QnM',
              parameter: 'Percentage of students benefited by scholarships and freeships provided by the Government and Non-Government agencies and philanthropists during last five years',
              weightage: 20,
              link: 'https://ytipharmacy.com/naac/5.1.1facesheet.pdf'
            },
            {
              metric: '5.1.2 QnM',
              parameter: 'Capacity building and skills enhancement initiatives taken by the institution include the following\n1. Soft skills\n2. Language and communication skills\n3. Life skills (Yoga, physical fitness, health and hygiene)\n4. ICT/computing skills',
              weightage: 10,
              link: 'https://ytipharmacy.com/naac/5.1.2facesheet.pdf'
            },
            {
              metric: '5.1.3 QnM',
              parameter: 'Percentage of students benefitted by guidance for competitive examinations and career counseling offered by the Institution during the last five years',
              weightage: 10,
              link: 'https://ytipharmacy.com/naac/5.1.3facesheet.pdf'
            },
            {
              metric: '5.1.4 QnM',
              parameter: 'The Institution has a transparent mechanism for timely redressal of student grievances including sexual harassment and ragging cases\n1. Implementation of guidelines of statutory/regulatory bodies\n2. Organisation wide awareness and undertakings on policies with zero tolerance\n3. Mechanisms for submission of online/offline students’ grievances\n4. Timely redressal of the grievances through appropriate committees',
              weightage: 10,
              link: 'https://ytipharmacy.com/naac/5.1.4facesheet.pdf'
            },
            {
              metric: '5.2.1 QnM',
              parameter: 'Percentage of placement of outgoing students and students progressing to higher education during the last five years',
              weightage: 25,
              link: 'https://ytipharmacy.com/naac/5.2.1facesheet.pdf'
            },
            {
              metric: '5.2.2 QnM',
              parameter: 'Percentage of students qualifying in state/national/ international level examinations during the last five years\n(eg: JAM/CLAT/GATE/ GMAT/ CAT/ GRE/ TOEFL/ Civil Services/State government examinations)',
              weightage: 10,
              link: 'https://ytipharmacy.com/naac/5.2.2facesheet.pdf'
            },
            {
              metric: '5.3.1 QnM',
              parameter: 'Number of awards/medals for outstanding performance in sports/cultural activities at University / state/ national / international level (award for a team event should be counted as one) during the last five years',
              weightage: 20,
              link: 'https://ytipharmacy.com/naac/5.3.1facesheet.pdf'
            },
            {
              metric: '5.3.2 QnM',
              parameter: 'Average number of sports and cultural programs in which students of the Institution participated during last five years (organised by the institution/other institutions)',
              weightage: 25,
              link: 'https://ytipharmacy.com/naac/5.3.2facesheet.pdf'
            },
            {
              metric: '5.4.1 QlM',
              parameter: 'There is a registered Alumni Association that contributes significantly to the development of the institution through financial and/or other support services',
              weightage: 10,
              link: 'https://ytipharmacy.com/naac/5.4.1facesheet.pdf'
            }
          ]
        },
        {
          title: 'CRITERION 6',
          name: 'Criterion 6 - Governance, Leadership and Management (100)',
          tableData: [
            {
              metric: '6.1.1 QlM',
              parameter: 'The governance and leadership is in accordance with vision and mission of the institution and it is visible in various institutional practices such as decentralization and participation in the institutional governance',
              weightage: 15,
              link: 'https://ytipharmacy.com/naac/6.1.1facesheet.pdf'
            },
            {
              metric: '6.2.1 QlM',
              parameter: 'The functioning of the institutional bodies is effective and efficient as visible from policies, administrative setup, appointment and service rules, procedures, deployment of institutional Strategic/perspective/development plan etc',
              weightage: 8,
              link: 'https://ytipharmacy.com/naac/6.2.1facesheet.pdf'
            },
            {
              metric: '6.2.2 QnM',
              parameter: 'Implementation of e-governance in areas of operation\n1.Administration\n2.Finance and Accounts\n3.Student Admission and Support\n4.Examination',
              weightage: 4,
              link: 'https://ytipharmacy.com/naac/6.2.2facesheet.pdf'
            },
            {
              metric: '6.3.1 QlM',
              parameter: 'The institution has effective welfare measures and Performance Appraisal System for teaching and non-teaching staff',
              weightage: 6,
              link: 'https://ytipharmacy.com/naac/6.3.1facesheet.pdf'
            },
            {
              metric: '6.3.2 QnM',
              parameter: 'Percentage of teachers provided with financial support to attend conferences/workshops and towards membership fee of professional bodies during the last five years',
              weightage: 12,
              link: 'https://ytipharmacy.com/naac/6.3.2facesheet.pdf'
            },
            {
              metric: '6.3.3 QnM',
              parameter: 'Percentage of teaching and non-teaching staff participating in Faculty development Programmes (FDP), professional development/administrative training programs during the last five years',
              weightage: 15,
              link: 'https://ytipharmacy.com/naac/6.3.3facesheet.pdf'
            },
            {
              metric: '6.4.1 QlM',
              parameter: 'Institution has strategies for mobilization and optimal utilization of resources and funds from various sources (government/ nongovernment organizations) and it conducts financial audits regularly (internal and external)',
              weightage: 10,
              link: 'https://ytipharmacy.com/naac/6.4.1facesheet.pdf'
            },
            {
              metric: '6.5.1 QlM',
              parameter: 'Internal Quality Assurance Cell (IQAC) has contributed significantly for institutionalizing the quality assurance strategies\nand processes. It reviews teaching learning process, structures & methodologies of operations and learning outcomes at periodic intervals and records the incremental improvement in various activities',
              weightage: 15,
              link: 'https://ytipharmacy.com/naac/6.5.1facesheet.pdf'
            },
            {
              metric: '6.5.2 QnM',
              parameter: 'Quality assurance initiatives of the institution include:\n1.Regular meeting of Internal Quality Assurance Cell (IQAC); Feedback collected, analysed and used for improvements\n2.Collaborative quality initiatives with other institution(s)/membership of international networks\n3.Participation in NIRF\n4.any other quality audit/accreditation recognized by state,national or international agencies such as NAAC, NBA etc',
              weightage: 15,
              link: 'https://ytipharmacy.com/naac/6.5.2facesheet.pdf'
            }
          ]

        },
        {
          title: 'CRITERION 7',
          name: 'Criterion 7 - Institutional Values and Best Practices (100)',
          tableData: [
            {
              metric: '7.1.1 QlM',
              parameter: 'Measures initiated by the Institution for the promotion of gender equity and Institutional initiatives to celebrate / organize national and international commemorative days, events and festivals during the last five years (Within 500 words)',
              weightage: 10,
              link: 'https://ytipharmacy.com/naac/7.1.1facesheet.pdf'
            },
            {
              metric: '7.1.2 QnM',
              parameter: 'The Institution has facilities and initiatives for 1. Alternate sources of energy and energy conservation measures 2. Management of the various types of degradable and nondegradable 3. Waste Water conservation 4. Green campus initiatives 5. Disabled-friendly, barrier free environment',
              weightage: 20,
              link: 'https://ytipharmacy.com/naac/7.1.2facesheet.pdf'
            },
            {
              metric: '7.1.3 QnM',
              parameter: 'Quality audits on environment and energy regularly undertaken by the Institution. The institutional environment and energy initiatives are confirmed through the following 1. Green audit / Environment audit 2. Energy audit 3. Clean and green campus initiatives 4. Beyond the campus environmental promotion and sustainability activities',
              weightage: 10,
              link: 'https://ytipharmacy.com/naac/7.1.3facesheet.pdf'
            },
            {
              metric: '7.1.4 QlM',
              parameter: 'Describe the Institutional efforts/initiatives in providing an inclusive environment i.e., tolerance and harmony towards cultural, regional, linguistic, communal socioeconomic diversity and Sensitization of students and employees to the constitutional obligations: values, rights, duties and responsibilities of citizens (Within 500 words)',
              weightage: 10,
              link: 'https://ytipharmacy.com/naac/7.1.4facesheet.pdf'
            },
            {
              metric: '7.2.1 QlM',
              parameter: 'Describe two best practices successfully implemented by the Institution as per NAAC format provided in the Manual',
              weightage: 30,
              link: 'https://ytipharmacy.com/naac/7.2.1facesheet.pdf'
            },
            {
              metric: '7.3.1 QlM',
              parameter: 'Portray the performance of the Institution in one area distinctive to its priority and thrust within 1000 words',
              weightage: 20,
              link: 'https://ytipharmacy.com/naac/7.3.1facesheet.pdf'
            }
          ]
        },
        {
          title: 'EXTENDED PROFILE',
          name: 'Extended Profile',
          list: [
            {
              title: '',
              links: [
                {
                  name: '1.1 Number of students year wise during the last five years',
                  link: 'https://ytipharmacy.com/naac/Extended_Profile_1.1facesheet.pdf'
                },
                {
                  name: '2.1. Number of full time teachers during the last five years',
                  link: 'https://ytipharmacy.com/naac/Extended_Profile_2.1facesheet.pdf'
                },
                {
                  name: '3.1 Expenditure excluding salary component year wise during the last five years',
                  link: 'https://ytipharmacy.com/naac/Extended_Profile_3.1facesheet.pdf'
                }
              ]
            }
          ]
        },
        {
          title: 'DVV CLARIFICATION',
          name: 'DVV CLARIFICATION',
          tableFields: [
            {
              key: 'metric',
              label: 'Metric'
            },
            {
              key: 'link',
              label: 'Parameter'
            }
          ],
          tableGroupData: [
            {
              name: 'EXTENDED PROFILE',
              tableGroupData: [
                {
                  metric: '2.1',
                  description: 'Number of teaching staff / full time teachers during the last five years (Without repeat Count)',
                  link: 'https://ytipharmacy.com/naac/dvv2.1extfacingsheet.pdf'
                },
                {
                  metric: '2.2',
                  description: 'Number of teaching staff / full time teachers year wise during the last five years.',
                  link: 'https://ytipharmacy.com/naac/dvv2.2extfacingsheet.pdf'
                }
              ]
            },
            {
              name: 'CRITERION 1',
              tableGroupData: [
                {
                  metric: '1.2.2',
                  description: 'Number of students enrolled in Certificate/ Value added courses and also completed online courses of MOOCs, SWAYAM, NPTEL etc. as against the total number of students during the last five years.',
                  link: 'https://ytipharmacy.com/naac/dvv1.2.2facingsheet.pdf'
                },
                {
                  metric: '1.3.2',
                  description: 'Number of students undertaking project work/field work / internships.',
                  link: 'https://ytipharmacy.com/naac/dvv1.3.2facingsheet.pdf'
                }
              ]
            },
            {
              name: 'CRITERION 2',
              tableGroupData: [
                {
                  metric: '2.4.1',
                  description: 'Number of sanctioned posts year wise during the last five years',
                  link: 'https://ytipharmacy.com/naac/dvv2.4.1facingsheet.pdf'
                }
              ]
            },
            {
              name: 'CRITERION 3',
              tableGroupData: [
                {
                  metric: '3.1.1',
                  description: 'Total Grants from Government and non-governmental agencies for research projects / endowments in the institution during the last five years (INR in Lakhs)',
                  link: 'https://ytipharmacy.com/naac/dvv3.1.1facingsheet.pdf'
                },
                {
                  metric: '3.2.2',
                  description: 'Total number of workshops/seminars/conferences including programs conducted on Research Methodology, Intellectual Property Rights (IPR) and entrepreneurship year wise during last five years.',
                  link: 'https://ytipharmacy.com/naac/dvv3.2.2facingsheet.pdf'
                },
                {
                  metric: '3.3.1',
                  description: 'Number of research papers in the Journals notified on UGC CARE list year wise during the last five years.',
                  link: 'https://ytipharmacy.com/naac/dvv3.3.1facingsheet.pdf'
                },
                {
                  metric: '3.4.3',
                  description: 'Number of extension and outreach Programs conducted in collaboration with industry, community, and Non- Government Organizations through NSS/ NCC etc., year wise during the last five years',
                  link: 'https://ytipharmacy.com/naac/dvv3.4.3facingsheet.pdf'
                },
                {
                  metric: '3.5.1',
                  description: 'Number of functional MoUs/linkages with institutions/ industries in India and abroad for internship, on-the-job training, project work, student / faculty exchange and collaborative research during the last five years',
                  link: 'https://ytipharmacy.com/naac/dvv3.5.1facingsheet.pdf'
                }
              ]
            },
            {
              name: 'CRITERION 4',
              tableGroupData: [
                {
                  metric: '4.1.2',
                  description: 'Expenditure for infrastructure development and augmentation, excluding salary year wise during last five years (INR in lakhs)',
                  link: 'https://ytipharmacy.com/naac/dvv4.1.2facingsheet.pdf'
                },
                {
                  metric: '4.3.2',
                  description: 'Number of computers available for students usage during the latest completed academic year:',
                  link: 'https://ytipharmacy.com/naac/dvv4.3.2facingsheet.pdf'
                },
                {
                  metric: '4.4.1',
                  description: 'Expenditure incurred on maintenance of infrastructure (physical facilities and academic support facilities) excluding salary component year wise during the last five years (INR in lakhs)',
                  link: 'https://ytipharmacy.com/naac/dvv4.4.1facingsheet.pdf'
                }
              ]
            },
            {
              name: 'CRITERION 5',
              tableGroupData: [
                {
                  metric: '5.2.1',
                  description: 'Number of outgoing students placed and / or progressed to higher education year wise during the last five years.',
                  link: 'https://ytipharmacy.com/naac/dvv5.2.1facingsheet.pdf'
                },
                {
                  metric: '5.3.2',
                  description: 'Number of sports and cultural programs in which students of the Institution participated year wise during last five years',
                  link: 'https://ytipharmacy.com/naac/dvv5.3.2facingsheet.pdf'
                }
              ]
            },
            {
              name: 'CRITERION 6',
              tableGroupData: [
                {
                  metric: '6.3.3',
                  description: 'Total number of teaching and non-teaching staff participating in Faculty development Programmes (FDP), Management Development Programmes (MDPs) professional development / administrative training programs during the last five years',
                  link: 'https://ytipharmacy.com/naac/dvv6.3.3facingsheet.pdf'
                },
                {
                  metric: '6.5.2',
                  description: 'Quality assurance initiatives of the institution include:<br>Regular meeting of Internal Quality Assurance Cell (IQAC); quality improvement initiatives identified and implemented<br>Academic and Administrative Audit (AAA) and follow-up action taken<br>Collaborative quality initiatives with other institution(s)<br>Participation in NIRF and other recognized rankings<br>Any other quality audit/accreditation recognized by state, national or international agencies such as NAAC, NBA etc.',
                  link: 'https://ytipharmacy.com/naac/dvv6.5.2facingsheet.pdf'
                }
              ]
            }
          ]
        },
        {
          title: 'BEST PRACTICE',
          name: 'BEST PRACTICE',
          list: [
            {
              title: '',
              links: [
                {
                  name: 'BEST PRACTICE',
                  link: 'https://ytipharmacy.com/naac/BEST_PRACTICE.pdf'
                }
              ]
            }
          ]
        },
        {
          title: 'HR POLICY',
          name: 'HR POLICY',
          list: [
            {
              title: '',
              links: [
                {
                  name: 'HR Policy',
                  link: 'https://ytipharmacy.com/naac/HR_Policy.pdf'
                }
              ]
            }
          ]
        },
        {
          title: 'AWARDS AND RECOGNIZATION',
          name: 'AWARDS AND RECOGNIZATION',
          list: [
            {
              title: '',
              links: [
                {
                  name: 'Awards and Recognizations',
                  link: 'https://ytipharmacy.com/naac/RECOGNITION_AND_AWARDS.pdf'
                }
              ]
            }
          ]
        },
        {
          title: 'ENVIRONMENTAL ACTIVITIES',
          name: 'ENVIRONMENTAL ACTIVITIES',
          list: [
            {
              title: '',
              links: [
                {
                  name: 'Environmental Activities',
                  link: 'https://ytipharmacy.com/naac/Environmental_Activities.pdf'
                }
              ]
            }
          ]
        },
        {
          title: 'DISABLED FRIENDLY CAMPUS',
          name: 'DISABLED FRIENDLY CAMPUS',
          list: [
            {
              title: '',
              links: [
                {
                  name: 'Disabled Friendly Environment',
                  link: 'https://ytipharmacy.com/naac/Disable_Friendly_EE.pdf'
                }
              ]
            }
          ]
        },
        {
          title: 'INTERNAL AND EXTERNAL AUDIT',
          name: 'INTERNAL AND EXTERNAL AUDIT',
          list: [
            {
              title: '',
              links: [
                {
                  name: 'Internal Audit',
                  link: 'https://ytipharmacy.com/naac/internal_audit.pdf'
                },
                {
                  name: 'External Audit',
                  link: 'https://ytipharmacy.com/naac/External_Audit.pdff'
                }
              ]
            }
          ]
        },
        {
          title: 'Stake Holders Feedback',
          name: 'Stake Holders Feedback',
          feedbackForms: [
            {
              icon: 'fa fa-child',
              name: 'Students Feedback',
              link: 'http://ytipharmacy.kredovoiceout.in/#/el/10000014'
            },
            {
              icon: 'fa fa-user',
              name: 'Faculty Feedback',
              link: 'http://ytipharmacy.kredovoiceout.in/#/el/10000015'
            },
            {
              icon: 'fa fa-briefcase',
              name: 'Employer Feedback',
              link: 'http://ytipharmacy.kredovoiceout.in/#/el/10000017'
            },
            {
              icon: 'fa fa-graduation-cap',
              name: 'Alumni Feedback',
              link: 'http://ytipharmacy.kredovoiceout.in/#/el/10000016'
            },
            {
              icon: 'fa fa-question',
              name: 'Questionaire',
              link: ''
            },
            {
              icon: 'fa fa-university',
              name: 'University Communucation',
              link: ''
            },
            {
              icon: 'fa fa-users',
              name: 'Parents Feedback',
              link: ''
            },
            {
              icon: 'fa fa-check',
              name: 'Analysis and Action Report',
              link: ''
            }
          ]
        }
      ]
    }
  },
  filters: {
    toId (value) {
      return 'tab_' + value.toLowerCase().replace(/\s+/g, '-')
    },
    toLink (value) {
      return '#tab_' + value.toLowerCase().replace(/\s+/g, '-')
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 35px;
}

.text-dark {
  color: #0f8f46;
  text-decoration: underline;
}

.naac-tabs {
  padding: 15px !important;

  li {
    margin: 10px !important;
    border: 1px solid #0f8f46 !important;
  }
}

.naac-title {
  background-color: #0f8f46;
  padding: 15px;
  margin-bottom: 20px;

  h5 {
    color: white !important;
  }
}

.form-box {
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #0f8f46;
  text-align: center;
  cursor: pointer;
  color: #0f8f46;
  i {
    font-size: 20px;
    margin-bottom: 5px;
  }
}
.form-box:hover {
  background-color: #0f8f46;
  color: white !important;
}
</style>
